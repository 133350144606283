import React from 'react';
import { footerLogo, hero, logo } from '../../assets';

const Footer = () => {
  return (
    <footer className="bg-[#1b2335] pt-10 pb-10 md:pt-0 md:pb-0">
      <div className='max-w-containerSm md:max-w-container mx-auto'>
      <div className="md:h-[329px]">
      <div className='md:h-[60px]'>
      </div>
        <div className='md:h-36 md:flex justify-between'>
          <div className='w-[223px] mr-[80px]'> 
        <img className='h-[43px] mb-4' src={logo}></img>
        <p className='text-white text-sm font-normal'>İnşaatda Yeni Səhifə</p>
          </div>
          <div className='w-[81px] mr-[80px]'>
          <p className='mb-4 text-white text-base font-semibold'>Bağlantılar</p>
          <a href='/'><p className='mb-2 text-white text-sm font-normal'>Ana səhifə</p></a>
          <a href='/about'><p className=' mb-2 text-white text-sm font-normal'>Haqqımızda</p></a>
          <a href='/services'><p className='mb-2 text-white text-sm font-normal'>Xidmətlərimiz</p></a>
          <a href='/projects'><p className='mb-2 text-white text-sm font-normal'>Layihələrimiz</p></a>
          <a href='/contact'><p className='mb-2 text-white text-sm font-normal'>Əlaqə</p></a>
          </div>
          <div className='md:w-[111px] mr-[80px]'>
          <p className='mb-4 text-white text-base font-semibold'>Xidmətlərimiz</p>
          <a href='/services'> <p className='mb-2 text-white text-sm font-normal'>Əsas xidmətlər</p></a>
         <a href='/services/infrastruktur'> <p className='mb-2 text-white text-sm font-normal'>İnfrastruktur</p></a>
         <a href='/services/tikinti'><p className='mb-2 text-white text-sm font-normal'>Tikinti növləri</p></a>
          <a href='/services/interyer'><p className='mb-2 text-white text-sm font-normal'>İnteryer</p></a>
          <a href='/services/eksteryer'><p className='mb-2 text-white text-sm font-normal'>Eksteryer</p></a>
          <a href='/services/texnika'><p className='mb-2 text-white text-sm font-normal'>Texnika</p></a>
          </div>
          <div className='md:w-[145px]  mr-[80px]'>
          <p className='mb-4 text-white text-base font-semibold'>Layihələrimiz</p>
          <a href='/projects'><p className='mb-2 text-white text-sm font-normal'>Bitmiş layihələr</p></a>
         <a href='/projects'> <p className='text-white text-sm font-normal'>Davam edən layihələr</p></a>
          </div>
          <div className='md:w-[254px]'>
         <div className='flex mb-4'>
         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.0376 5.31617L10.6866 6.4791C11.2723 7.52858 11.0372 8.90532 10.1147 9.8278C10.1147 9.8278 10.1147 9.8278 10.1147 9.8278C10.1146 9.82792 8.99588 10.9468 11.0245 12.9755C13.0525 15.0035 14.1714 13.8861 14.1722 13.8853C14.1722 13.8853 14.1722 13.8853 14.1722 13.8853C15.0947 12.9628 16.4714 12.7277 17.5209 13.3134L18.6838 13.9624C20.2686 14.8468 20.4557 17.0692 19.0628 18.4622C18.2258 19.2992 17.2004 19.9505 16.0669 19.9934C14.1588 20.0658 10.9183 19.5829 7.6677 16.3323C4.41713 13.0817 3.93421 9.84122 4.00655 7.93309C4.04952 6.7996 4.7008 5.77423 5.53781 4.93723C6.93076 3.54428 9.15317 3.73144 10.0376 5.31617Z" fill="white"/>
</svg>
            <div>
            <p className='text-white text-sm font-normal ml-1'> +994 51 295 46 87</p>
            <p className='text-white text-sm font-normal ml-1'> +994 12 565 00 20</p>
            </div>
         </div>
         <div className='flex mb-4'>
         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.17157 5.17157C2 6.34315 2 8.22876 2 12C2 15.7712 2 17.6569 3.17157 18.8284C4.34315 20 6.22876 20 10 20H14C17.7712 20 19.6569 20 20.8284 18.8284C22 17.6569 22 15.7712 22 12C22 8.22876 22 6.34315 20.8284 5.17157C19.6569 4 17.7712 4 14 4H10C6.22876 4 4.34315 4 3.17157 5.17157ZM18.5762 7.51986C18.8413 7.83807 18.7983 8.31099 18.4801 8.57617L16.2837 10.4066C15.3973 11.1452 14.6789 11.7439 14.0448 12.1517C13.3843 12.5765 12.7411 12.8449 12 12.8449C11.2589 12.8449 10.6157 12.5765 9.95518 12.1517C9.32112 11.7439 8.60271 11.1452 7.71636 10.4066L5.51986 8.57617C5.20165 8.31099 5.15866 7.83807 5.42383 7.51986C5.68901 7.20165 6.16193 7.15866 6.48014 7.42383L8.63903 9.22291C9.57199 10.0004 10.2197 10.5384 10.7666 10.8901C11.2959 11.2306 11.6549 11.3449 12 11.3449C12.3451 11.3449 12.7041 11.2306 13.2334 10.8901C13.7803 10.5384 14.428 10.0004 15.361 9.22291L17.5199 7.42383C17.8381 7.15866 18.311 7.20165 18.5762 7.51986Z" fill="white"/>
</svg>
<p className='text-white text-sm font-normal ml-1'>info@fuga.az</p>
         </div>
         <div className='flex mb-4'>
         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C7.58172 2 4 6.00258 4 10.5C4 14.9622 6.55332 19.8124 10.5371 21.6744C11.4657 22.1085 12.5343 22.1085 13.4629 21.6744C17.4467 19.8124 20 14.9622 20 10.5C20 6.00258 16.4183 2 12 2ZM12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12Z" fill="white"/>
</svg>
<p className='text-white text-sm font-normal ml-1'>Bakı ş., Xətai ray. , 8 noyabr pr., 15, Azure Biznes Mərkəzi</p>
         </div>
         <div className='flex mb-4'>
         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 7.25C12.4142 7.25 12.75 7.58579 12.75 8V11.6893L15.0303 13.9697C15.3232 14.2626 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2626 15.3232 13.9697 15.0303L11.4697 12.5303C11.329 12.3897 11.25 12.1989 11.25 12V8C11.25 7.58579 11.5858 7.25 12 7.25Z" fill="#C1C1C1"/>
</svg>
<p className='text-white text-sm font-normal ml-1'>İş saatları: 09:00 - 18:00</p>

         </div>
          </div>
        </div>
      </div>
      </div>

    </footer>
  );
};

export default Footer;