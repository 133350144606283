import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { hero, projects1, projects2, projects3, projects4 } from '../../assets/index';
import { useTranslation } from 'react-i18next';
import Header from "../../Components/Header/Header";

const UnfinishedProjects = () => {
  const { t, i18n } = useTranslation();
  
  // Projeler listesi
 
  const ongoingProjects = [
    { id: 5, name: 'Layihə5', image: projects1 },
    { id: 6, name: 'Layihə6', image: projects2 },
    { id: 7, name: 'Layihə7', image: projects3 },
    { id: 8, name: 'Layihə8', image: projects4 },
    { id: 9, name: 'Layihə5', image: projects1 },
    { id: 10, name: 'Layihə6', image: projects2 },
    { id: 11, name: 'Layihə7', image: projects3 },
    { id: 12, name: 'Layihə8', image: projects4 }
  ];

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <>
    <Header/>
    <div className='bg-[#FAFAFA]'>
      <div className="pt-[164px] pb-[80px] max-w-container mx-auto">
        <div className='h-[75px] mb-[40px] flex justify-between'>
          <div className='w-[463px]'>
            <p className='text-[#405d72] text-[20px] font-semibold mb-4'>Layihələrimiz</p>
            <p className='text-black text-3xl font-bold mb-3'>Davam edən layihələr</p>
          </div>
        </div>

        {/* Projeler grid sistemi ile gösteriliyor */}
        <div className='grid grid-cols-3 gap-5'>
          {ongoingProjects.map((project) => (
           <a href='/projects/1'>
             <div key={project.id}>
              <img src={project.image} alt={project.name} className='h-[269px] w-[426px] rounded-lg mb-3' />
              <p className='text-black text-base font-semibold'>{project.name}</p>
            </div>
           </a>
          ))}
        </div>
      </div>
    </div></>
  );
};

export default UnfinishedProjects;
