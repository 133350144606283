import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { allInOne, arrow, barkodOxuyucu, barkodPrinter, barkodProcessor, cardReader, dataTerminal, metal, pulQutulari, svg1, svg2, svg3, svg4, svg5, svg6, svg7, svg8, svgWhite1, svgWhite2, svgWhite3, svgWhite4, svgWhite5, svgWhite6, svgWhite7, whiteArrow } from '../../../assets/index';
import { useTranslation } from 'react-i18next';


export const products = [
  {
    image: pulQutulari,
    name: "Bio-L birtərəfli", 
    path: "/products/1",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  },
  {
    image: allInOne,
    name: "Bio-L birtərəfli",
    path: "/products/2",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  },
  {
    image: barkodProcessor,
    name: "Bio-L birtərəfli",
    path: "/products/3",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  },

  {
    image: cardReader,
    name: "Bio-L birtərəfli",
    path: "/products/4",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  }

];

const ServicesShort = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
   <div className='bg-[#f4f4f4]'>
     <div className="pt-[80px] pb-[80px] md:max-w-container max-w-containerSm mx-auto "> 
        <div className='mb-[20px] md:flex justify-between md:h-[225px]'>
     <div className='md:w-[478px]'>
   <p className='text-[#1b2335] text-[20px] font-semibold mb-4'>Xidmətlərimiz</p>
   <p className='text-black text-[22px] md:text-4xl font-bold mb-3'>Professional inşaat xidmətlərimiz</p>
   <p className='text-black text-base font-normal mb-3'>Peşəkar yanaşma, innovativ texnologiyalar və mükəmməlliyə doğru hər addımda xidmətinizdəyik.</p>
     </div>
     <div className='md:w-[797px] md:flex justify-between '>
     <div className='rounded-lg border border-[#1b2335]/20 md:w-[230px] hover:bg-[#1b2335] hover:text-[white] group transition duration-300 ease-in-out'>
  <div className='mt-4 mb-4 mr-6 ml-6 h-[193px]'>
    <p className='mb-[53px] text-[#f1b559] text-lg font-normal group-hover:text-white'>01</p> 
    <img className="w-10 h-10 mb-2 group-hover:hidden" src={svg1} alt="icon" />
    <img className="w-10 h-10 mb-2 hidden group-hover:block" src={svgWhite1} alt="icon hover" />
    <p className='mb-[20px] text-black text-xl font-bold group-hover:text-white'>Əsas xidmətlər</p>
    <div className="flex">
      <a href="/services/all">
        <p className='text-black text-sm font-normal group-hover:text-white mr-1'>Daha ətraflı</p>
      </a>
      <img className="w-5 h-5 group-hover:hidden" src={arrow} alt="icon" />
      <img className="w-5 h-5 mb-2 hidden group-hover:block" src={whiteArrow} alt="icon hover" />
    </div>
  </div>
</div>

<div className='rounded-lg border border-[#1b2335]/20 md:w-[230px] hover:bg-[#1b2335] hover:text-[white] group transition duration-300 ease-in-out mt-5 md:mt-0'>
  <div className='mt-4 mb-4 mr-6 ml-6 h-[193px]'>
    <p className='mb-[53px] text-[#f1b559] text-lg font-normal group-hover:text-white'>02</p>
    <img className="w-10 h-10 mb-2 group-hover:hidden" src={svg3} alt="icon" />
    <img className="w-10 h-10 mb-2 hidden group-hover:block" src={svg8} alt="icon hover" />
    <p className='mb-[20px] text-black text-xl font-bold group-hover:text-white'>Tikinti növləri</p>
    <div className="flex">
      <a href="/services/tikinti-nov">
        <p className='text-black text-sm font-normal group-hover:text-white mr-1'>Daha ətraflı</p> 
      </a>
      <img className="w-5 h-5 group-hover:hidden" src={arrow} alt="icon" />
      <img className="w-5 h-5 mb-2 hidden group-hover:block" src={whiteArrow} alt="icon hover" />
    </div>
  </div>
</div>

<div className='rounded-lg border border-[#1b2335]/20 md:w-[230px] hover:bg-[#1b2335] hover:text-[white] group transition duration-300 ease-in-out mt-5 md:mt-0'>
  <div className='mt-4 mb-4 mr-6 ml-6 h-[193px]'>
    <p className='mb-[53px] text-[#f1b559] text-lg font-normal group-hover:text-white'>03</p>
    <img className="w-10 h-10 mb-2 group-hover:hidden" src={svg2} alt="icon" />
    <img className="w-10 h-10 mb-2 hidden group-hover:block" src={svgWhite2} alt="icon hover" />
    <p className='mb-[20px] text-black text-xl font-bold group-hover:text-white'>İnfrastruktur</p>
    <div className="flex">
      <a href="/services/infrastruktur">
        <p className='text-black text-sm font-normal group-hover:text-white mr-1'>Daha ətraflı</p>
      </a>
      <img className="w-5 h-5 group-hover:hidden" src={arrow} alt="icon" />
      <img className="w-5 h-5 mb-2 hidden group-hover:block" src={whiteArrow} alt="icon hover" />
    </div>
  </div>
</div>
     </div>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-4 md:h-[225px] gap-5'>
        <div className='rounded-lg border border-[#1b2335]/20 md:w-[315px] hover:bg-[#1b2335] hover:text-[white] group transition duration-300 ease-in-out'>
  <div className='mt-4 mb-4 mr-6 ml-6 md:h-[193px]'>
    <p className='mb-[23px] text-[#f1b559] text-lg font-normal group-hover:text-white'>05</p>
    <img className="w-10 h-10 mb-2 group-hover:hidden" src={svg7} alt="icon" />
    <img className="w-10 h-10 mb-2 hidden group-hover:block" src={svgWhite7} alt="icon hover" />
    <p className='mb-[20px] text-black text-xl font-bold group-hover:text-white'>Metal konstruksiyaların istehsalı</p>
    <div className="flex">
      <a href="/services/metal">
        <p className='text-black text-sm font-normal group-hover:text-white mr-1'>Daha ətraflı</p>
      </a>
      <img className="w-5 h-5 group-hover:hidden" src={arrow} alt="icon" />
      <img className="w-5 h-5 mb-2 hidden group-hover:block" src={whiteArrow} alt="icon hover" />
    </div>
  </div>
</div>
<div className='rounded-lg border border-[#1b2335]/20 md:w-[315px] hover:bg-[#1b2335] hover:text-[white] group transition duration-300 ease-in-out'>
  <div className='mt-4 mb-4 mr-6 ml-6 h-[193px]'>
    <p className='mb-[53px] text-[#f1b559] text-lg font-normal group-hover:text-white'>04</p>
    <img className="w-10 h-10 mb-2 group-hover:hidden" src={svg4} alt="icon" />
    <img className="w-10 h-10 mb-2 hidden group-hover:block" src={svgWhite4} alt="icon hover" />
    <p className='mb-[20px] text-black text-xl font-bold group-hover:text-white'>İnteryer</p>
    <div className="flex">
      <a href="/services/interyer">
        <p className='text-black text-sm font-normal group-hover:text-white mr-1'>Daha ətraflı</p>
      </a>
      <img className="w-5 h-5 group-hover:hidden" src={arrow} alt="icon" />
      <img className="w-5 h-5 mb-2 hidden group-hover:block" src={whiteArrow} alt="icon hover" />
    </div>
  </div>
</div>
<div className='rounded-lg border border-[#1b2335]/20 md:w-[315px] hover:bg-[#1b2335] hover:text-[white] group transition duration-300 ease-in-out'>
  <div className='mt-4 mb-4 mr-6 ml-6 h-[193px]'>
    <p className='mb-[53px] text-[#f1b559] text-lg font-normal group-hover:text-white'>06</p>
    <img className="w-10 h-10 mb-2 group-hover:hidden" src={svg5} alt="icon" />
    <img className="w-10 h-10 mb-2 hidden group-hover:block" src={svgWhite5} alt="icon hover" />
    <p className='mb-[20px] text-black text-xl font-bold group-hover:text-white'>Eksteryer</p>
    <div className="flex">
      <a href="/services/eksteryer">
        <p className='text-black text-sm font-normal group-hover:text-white mr-1'>Daha ətraflı</p>
      </a>
      <img className="w-5 h-5 group-hover:hidden" src={arrow} alt="icon" />
      <img className="w-5 h-5 mb-2 hidden group-hover:block" src={whiteArrow} alt="icon hover" />
    </div>
  </div>
</div>
<div className='rounded-lg border border-[#1b2335]/20 md:w-[315px] hover:bg-[#1b2335] hover:text-[white] group transition duration-300 ease-in-out'>
  <div className='mt-4 mb-4 mr-6 ml-6 h-[193px]'>
    <p className='mb-[53px] text-[#f1b559] text-lg font-normal group-hover:text-white'>07</p>
    <img className="w-10 h-10 mb-2 group-hover:hidden" src={svg6} alt="icon" />
    <img className="w-10 h-10 mb-2 hidden group-hover:block" src={svgWhite6} alt="icon hover" />
    <p className='mb-[20px] text-black text-xl font-bold group-hover:text-white'>Texnika</p>
    <div className="flex">
      <a href="/services/texnika">
        <p className='text-black text-sm font-normal group-hover:text-white mr-1'>Daha ətraflı</p>
      </a>
      <img className="w-5 h-5 group-hover:hidden" src={arrow} alt="icon" />
      <img className="w-5 h-5 mb-2 hidden group-hover:block" src={whiteArrow} alt="icon hover" />
    </div>
  </div>
</div>
        </div>
      </div>
   </div>
  );
};

export default ServicesShort;
