import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../../Components/Header/Header";
import { hero, service1 } from "../../../assets";

export default function All() {

  const { t, i18n } = useTranslation();
  return (
    <>
    <Header/>
    <div className="mx-auto bg-[#FAFAFA] pt-[140px] pb-[144px]">
       <div className="max-w-container mx-auto h-[713px]">
    <p className="text-[#405d72] text-[20px] font-semibold mb-4">Xidmətlərimiz</p>
    <p className="text-black text-3xl font-bold mb-10">Əsas xidmətlər</p>
   <img className="h-[394px] bg-black/20 rounded-lg w-full" src={service1}></img>
   <p className="text-black text-base font-normal mt-6">Bina və müxtəlif tikililərin inşası “Fuga MMC” tərəfindən uğurlu şəkildə həyata keçirilən əsas fəaliyyət növüdür. Tam müasir, keyfiyyətli idarəetmə sistemi bütün inşaat işlərinin ən yüksək səviyyədə həyata keçirilməsini təmin edir. Bu sistem sayəsində, şirkətimiz tərəfindən inşa, təmir və ya rekonstruksiya olunan bina və digər tikililərin yüksək keyfiyyətinə tam əminliklə zəmanət veririk. Şirkətimiz tərəfindən görülən bütün işlərlə (tikinti quraşdırma işləri, daxili və xarici mühəndis sistemlərinin quraşdırılması, tamamlama işləri) bağlı zəmanət öhdəlikləri təmin olunur. İş prosesində müvafiq keyfiyyət sertifikatları ilə təsdiqlənən müasir avadanlıqlardan istifadə edilir.</p>
     </div>
     
</div>
    </>
  );
}

