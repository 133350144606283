import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header/Header'
import { certificate1, certificate2, certificate3, partners1, partners10, partners11, partners12, partners13, partners14, partners2, partners3, partners4, partners5, partners6, partners7, partners8, partners9, whyus, whyus2 } from '../../assets/index'
import axios from 'axios';

const Media = () => {

  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    axios
      .get("https://fuga.birsayt.xyz/fuga/videos.php/", {
        withCredentials: false,
      })
      .then((response) => {
        setProducts(response.data);
        setIsLoading(false);
      });
  }
  return (
    <>
    <Header/>
    <div className="mx-auto bg-[#FAFAFA] pt-[140px] pb-[144px]">
       <div className="max-w-container mx-auto h-[813px]">
    <p className="text-[#405d72] text-[20px] font-semibold mb-4">Media</p>
    {/* <p className="text-black text-3xl font-bold mb-10">Əməkdaşlıq etdiyimiz şirkətlər</p> */}
    {/* <div className='text-[black] h-auto lg:h-[960px] mb-[40px] lg:mb-[283px]'>
      <div className='max-w-container lg: mx-auto'>
      <div className='h-[145px] grid grid-cols-4 gap-8'>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px] bg-[white]'>
         <img className='h-[113px] object-contain' src={partners8} alt="Partner 1" />
         </div>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px] bg-[white]'>
         <img className='h-[86px] object-contain' src={partners9} alt="Partner 1" />
         </div>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px] bg-[white]'>
         <img className='h-[169px] object-contain' src={partners11} alt="Partner 1" />
         </div>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px] bg-[white]'>
         <img className='h-[190px] object-contain' src={partners12} alt="Partner 1" />
         </div>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px] bg-[white]'>
         <img className='h-[132px] object-contain' src={partners3} alt="Partner 1" />
         </div> 
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px] bg-[white]'>
         <img className='h-[65px] object-contain' src={partners4} alt="Partner 1" />
         </div>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px] bg-[white]'>
         <img className='h-[150px] object-contain' src={partners5} alt="Partner 1" />
         </div>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px] bg-[white]'>
         <img className='h-[156px] object-contain' src={partners6} alt="Partner 1" />
         </div>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px] bg-[white]'>
         <img className='h-[146px] object-contain' src={partners7} alt="Partner 1" />
         </div>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px] bg-[white]'>
         <img className='h-[120px] object-contain' src={partners10} alt="Partner 1" />
         </div>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px] bg-[white]'>
         <img className='h-[120px] object-contain' src={partners1} alt="Partner 1" />
         </div>
         <div className='border-2 w-[302px] h-[216px] flex justify-center items-center border-[#F0F0F0] rounded-[4px] bg-[white]'>
         <img className='h-[70px] object-contain' src={partners2} alt="Partner 1" />
         </div>
        </div>
      </div>
    </div> */}
    <div className='grid grid-cols-3'>
    {products?.map((user, key) => (
              <div key={key}>

<video  className='h-[245px] w-full object-cover' controls>
        <source  src={`https://sossecurity.az/sossecurity/${user.content}`} type="video/mp4" />
        Tarayıcınız video etiketini desteklemiyor.
      </video>
      <p className='mt-4 text-lg font-medium'>{user.name}</p>
              </div>
            ))}
    </div>
     </div>
     
</div>
  
    
    </>
  )
}

export default Media