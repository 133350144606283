import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { t } from "i18next";
import { carousel1, carousel2, carousel3, logo } from "../../../assets";
import Header from "../../Header/Header";
import MainHeader from "../../MainHeader/MainHeader";
import './Carousel.css'

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showText, setShowText] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const slides = [
    { img: carousel1, text: "İNŞAATDA YENİ SƏHİFƏ", content: "Fuga İnşaat, tikintinin hər mərhələsində müasir standartlara cavab verən peşəkar həllər təklif edir." },
    { img: carousel2, text: "İNŞAATDA YENİ SƏHİFƏ", content: "Fuga İnşaat, tikintinin hər mərhələsində müasir standartlara cavab verən peşəkar həllər təklif edir."  },
    { img: carousel3, text: "İNŞAATDA YENİ SƏHİFƏ", content: "Fuga İnşaat, tikintinin hər mərhələsində müasir standartlara cavab verən peşəkar həllər təklif edir." },
  ];

  useEffect(() => {
    const textTimer = setTimeout(() => {
      setShowText(true);
    }, 500);

    const buttonTimer = setTimeout(() => {
      setShowButton(true);
    }, 1500);

    return () => {
      clearTimeout(textTimer);
      clearTimeout(buttonTimer);
    };
  }, [activeIndex]);

  return (
    <div className="">
      {/* Header is placed outside the Swiper for it to stay on top */}
      <MainHeader/>

      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        onSlideChange={(swiper) => {
          setShowText(false);
          setShowButton(false);
          setActiveIndex(swiper.activeIndex);
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      > 
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="relative">
              <img src={slide.img} alt={`Slide ${index + 1}`} className="w-full md:h-[820px] h-[620px] object-cover" />
              {activeIndex === index && (
                <div className="absolute inset-0 flex flex-col justify-center items-center lg:items-start text-white p-4 lg:p-8 transition-opacity duration-1000">
                  <div className="md:w-[1320px] relative mx-auto mb-6 lg:mb-14 md:text-center lg:text-left">
                    <h2 className={`text-white text-[22px] md:text-5xl font-bold mb-4 mt-[116px] lg:mb-5 transition-all md:w-[60%] duration-1000 transform ${showText ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>
                      {t(slide.text)}
                    </h2>
                    <h2 className={` text-white text-[16px] md:text-xl font-normal mb-4 lg:mb-7 transition-all md:w-[50%] duration-1000 transform ${showText ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>
                      {t(slide.content)}
                    </h2>

                    <div className="">
                      <a href="/about">
                        <button className={`w-[146px] h-10 bg-[#f1b559] hover:bg-[#898989] rounded-lg transition-all duration-1000 transform ${showButton ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>
                          {t("Daha ətraflı")}
                        </button>
                      </a>
                    </div>
                    <h2 className={`text-white text-base font-normal mt-[176px] mb-2 transition-all w-[50%] duration-1000 transform ${showText ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>
                      Bizimlə əlaqə saxlayın
                    </h2>
                  
                    <div className="flex">
                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.2043 3.29024C23.3202 2.57468 23.9966 2.08928 24.7122 2.20512C24.7565 2.2136 24.899 2.24024 24.9736 2.25687C25.123 2.29012 25.3313 2.34132 25.591 2.41697C26.1103 2.56824 26.8359 2.81745 27.7067 3.21669C29.4502 4.01601 31.7704 5.41427 34.178 7.82192C36.5857 10.2296 37.9839 12.5497 38.7833 14.2932C39.1825 15.1641 39.4317 15.8896 39.583 16.409C39.6586 16.6687 39.7098 16.877 39.7431 17.0263C39.7597 17.101 39.7719 17.1609 39.7803 17.2052L39.7904 17.2598C39.9063 17.9754 39.4253 18.6798 38.7097 18.7956C37.9962 18.9111 37.324 18.4281 37.2053 17.7159C37.2017 17.6968 37.1917 17.6454 37.1809 17.5969C37.1593 17.5 37.1219 17.3463 37.0627 17.1431C36.9443 16.7365 36.7386 16.1321 36.3971 15.3872C35.7149 13.8992 34.4881 11.8443 32.3219 9.67807C30.1556 7.51182 28.1008 6.28507 26.6128 5.60287C25.8678 5.26135 25.2635 5.05566 24.8569 4.93724C24.6536 4.87803 24.3981 4.81939 24.3012 4.7978C23.589 4.67911 23.0888 4.00375 23.2043 3.29024Z" fill="#F1B559"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M23.6003 9.3268C23.7994 8.62982 24.5259 8.22624 25.2228 8.42537L24.8623 9.68737C25.2228 8.42537 25.2228 8.42537 25.2228 8.42537L25.2254 8.4261L25.228 8.42687L25.2339 8.42858L25.2474 8.43262L25.2821 8.44341C25.3085 8.45185 25.3414 8.46284 25.3807 8.47678C25.4593 8.50467 25.5632 8.54433 25.6906 8.59894C25.9455 8.7082 26.2939 8.87694 26.7218 9.13018C27.5783 9.63713 28.7477 10.4792 30.1214 11.8529C31.4951 13.2266 32.3371 14.3959 32.8441 15.2525C33.0973 15.6804 33.2661 16.0287 33.3753 16.2837C33.4299 16.4111 33.4696 16.515 33.4975 16.5936C33.5114 16.6329 33.5224 16.6658 33.5308 16.6922L33.5416 16.7268L33.5457 16.7404L33.5474 16.7462L33.5482 16.7489C33.5482 16.7489 33.5489 16.7514 32.2869 17.112L33.5489 16.7514C33.748 17.4484 33.3444 18.1749 32.6475 18.374C31.9564 18.5714 31.2364 18.1764 31.0301 17.4903L31.0236 17.4714C31.0143 17.4451 30.995 17.3933 30.9626 17.3177C30.8978 17.1666 30.7804 16.9195 30.5851 16.5894C30.1949 15.9301 29.4901 14.934 28.2652 13.709C27.0403 12.4841 26.0441 11.7794 25.3848 11.3892C25.0548 11.1939 24.8076 11.0764 24.6565 11.0117C24.5809 10.9793 24.5292 10.96 24.5029 10.9507L24.484 10.9442C23.7979 10.7379 23.4028 10.0179 23.6003 9.3268Z" fill="#F1B559"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.76304 7.71207C11.7032 4.77195 16.6647 4.99546 18.7118 8.66368L19.8476 10.6988C21.1845 13.0942 20.6148 16.1166 18.6577 18.0976C18.6317 18.1333 18.4935 18.3344 18.4763 18.6858C18.4544 19.1344 18.6137 20.1718 20.2209 21.779C21.8277 23.3857 22.8649 23.5454 23.3136 23.5236C23.6653 23.5065 23.8666 23.3683 23.9024 23.3422C25.8833 21.3852 28.9057 20.8155 31.3012 22.1523L33.3363 23.2881C37.0045 25.3353 37.228 30.2968 34.2879 33.2369C32.7152 34.8096 30.6241 36.2069 28.1668 36.3001C24.5251 36.4381 18.4785 35.4977 12.4904 29.5096C6.50224 23.5215 5.56181 17.4748 5.69986 13.8332C5.79302 11.3758 7.19038 9.28473 8.76304 7.71207ZM16.4196 9.94292C15.3714 8.06456 12.5544 7.63303 10.6192 9.56823C9.26234 10.9251 8.38022 12.4228 8.32298 13.9326C8.20785 16.9695 8.95762 22.2645 14.3465 27.6534C19.7354 33.0423 25.0305 33.7921 28.0673 33.677C29.5772 33.6197 31.0749 32.7376 32.4317 31.3808C34.3669 29.4455 33.9354 26.6286 32.057 25.5803L30.0219 24.4445C28.756 23.738 26.9777 23.979 25.7294 25.2273C25.6068 25.3498 24.8262 26.0781 23.4412 26.1455C22.0233 26.2145 20.307 25.5774 18.3648 23.6352C16.4219 21.6923 15.785 19.9756 15.8545 18.5575C15.9223 17.1723 16.6507 16.3925 16.7726 16.2706C18.0209 15.0223 18.2619 13.244 17.5554 11.978L16.4196 9.94292Z" fill="#F1B559"/>
</svg>
<p className=" text-white text-base font-normal ml-2 mt-2">+994 51 295 46 87</p>
                  </div>
                  <div className="flex">
                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.2043 3.29024C23.3202 2.57468 23.9966 2.08928 24.7122 2.20512C24.7565 2.2136 24.899 2.24024 24.9736 2.25687C25.123 2.29012 25.3313 2.34132 25.591 2.41697C26.1103 2.56824 26.8359 2.81745 27.7067 3.21669C29.4502 4.01601 31.7704 5.41427 34.178 7.82192C36.5857 10.2296 37.9839 12.5497 38.7833 14.2932C39.1825 15.1641 39.4317 15.8896 39.583 16.409C39.6586 16.6687 39.7098 16.877 39.7431 17.0263C39.7597 17.101 39.7719 17.1609 39.7803 17.2052L39.7904 17.2598C39.9063 17.9754 39.4253 18.6798 38.7097 18.7956C37.9962 18.9111 37.324 18.4281 37.2053 17.7159C37.2017 17.6968 37.1917 17.6454 37.1809 17.5969C37.1593 17.5 37.1219 17.3463 37.0627 17.1431C36.9443 16.7365 36.7386 16.1321 36.3971 15.3872C35.7149 13.8992 34.4881 11.8443 32.3219 9.67807C30.1556 7.51182 28.1008 6.28507 26.6128 5.60287C25.8678 5.26135 25.2635 5.05566 24.8569 4.93724C24.6536 4.87803 24.3981 4.81939 24.3012 4.7978C23.589 4.67911 23.0888 4.00375 23.2043 3.29024Z" fill="#F1B559"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M23.6003 9.3268C23.7994 8.62982 24.5259 8.22624 25.2228 8.42537L24.8623 9.68737C25.2228 8.42537 25.2228 8.42537 25.2228 8.42537L25.2254 8.4261L25.228 8.42687L25.2339 8.42858L25.2474 8.43262L25.2821 8.44341C25.3085 8.45185 25.3414 8.46284 25.3807 8.47678C25.4593 8.50467 25.5632 8.54433 25.6906 8.59894C25.9455 8.7082 26.2939 8.87694 26.7218 9.13018C27.5783 9.63713 28.7477 10.4792 30.1214 11.8529C31.4951 13.2266 32.3371 14.3959 32.8441 15.2525C33.0973 15.6804 33.2661 16.0287 33.3753 16.2837C33.4299 16.4111 33.4696 16.515 33.4975 16.5936C33.5114 16.6329 33.5224 16.6658 33.5308 16.6922L33.5416 16.7268L33.5457 16.7404L33.5474 16.7462L33.5482 16.7489C33.5482 16.7489 33.5489 16.7514 32.2869 17.112L33.5489 16.7514C33.748 17.4484 33.3444 18.1749 32.6475 18.374C31.9564 18.5714 31.2364 18.1764 31.0301 17.4903L31.0236 17.4714C31.0143 17.4451 30.995 17.3933 30.9626 17.3177C30.8978 17.1666 30.7804 16.9195 30.5851 16.5894C30.1949 15.9301 29.4901 14.934 28.2652 13.709C27.0403 12.4841 26.0441 11.7794 25.3848 11.3892C25.0548 11.1939 24.8076 11.0764 24.6565 11.0117C24.5809 10.9793 24.5292 10.96 24.5029 10.9507L24.484 10.9442C23.7979 10.7379 23.4028 10.0179 23.6003 9.3268Z" fill="#F1B559"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.76304 7.71207C11.7032 4.77195 16.6647 4.99546 18.7118 8.66368L19.8476 10.6988C21.1845 13.0942 20.6148 16.1166 18.6577 18.0976C18.6317 18.1333 18.4935 18.3344 18.4763 18.6858C18.4544 19.1344 18.6137 20.1718 20.2209 21.779C21.8277 23.3857 22.8649 23.5454 23.3136 23.5236C23.6653 23.5065 23.8666 23.3683 23.9024 23.3422C25.8833 21.3852 28.9057 20.8155 31.3012 22.1523L33.3363 23.2881C37.0045 25.3353 37.228 30.2968 34.2879 33.2369C32.7152 34.8096 30.6241 36.2069 28.1668 36.3001C24.5251 36.4381 18.4785 35.4977 12.4904 29.5096C6.50224 23.5215 5.56181 17.4748 5.69986 13.8332C5.79302 11.3758 7.19038 9.28473 8.76304 7.71207ZM16.4196 9.94292C15.3714 8.06456 12.5544 7.63303 10.6192 9.56823C9.26234 10.9251 8.38022 12.4228 8.32298 13.9326C8.20785 16.9695 8.95762 22.2645 14.3465 27.6534C19.7354 33.0423 25.0305 33.7921 28.0673 33.677C29.5772 33.6197 31.0749 32.7376 32.4317 31.3808C34.3669 29.4455 33.9354 26.6286 32.057 25.5803L30.0219 24.4445C28.756 23.738 26.9777 23.979 25.7294 25.2273C25.6068 25.3498 24.8262 26.0781 23.4412 26.1455C22.0233 26.2145 20.307 25.5774 18.3648 23.6352C16.4219 21.6923 15.785 19.9756 15.8545 18.5575C15.9223 17.1723 16.6507 16.3925 16.7726 16.2706C18.0209 15.0223 18.2619 13.244 17.5554 11.978L16.4196 9.94292Z" fill="#F1B559"/>
</svg>
<p className=" text-white text-base font-normal ml-2 mt-2">+994 12 565 00 20</p>
                  </div>
                  </div>
                </div>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carousel;
