import React from 'react';
import Carousel from '../../Components/home/Carousel/Carousel';
import Preferences from '../../Components/home/Preferences/Preferences';
import About from '../../Components/home/About/About';
import WhyUs from '../../Components/home/WhyUs/WhyUs';
import Contact from '../../Components/home/Contact/Contact';
import Team from '../../Components/home/Team/Team';
import Awards from '../../Components/home/Awards/Awards';
import ServicesShort from '../../Components/home/ServicesShort/ServicesShort';
import ProductShort from '../../Components/home/ProjectsShort/ProjectsShort';
import Hero from '../../Components/home/Hero/Hero';
import Kataloq from '../../Components/home/Kataloq/Kataloq';
import BlogsShort from '../../Components/home/BlogsShort/BlogsShort';
import ProjectsShort from '../../Components/home/ProjectsShort/ProjectsShort';
import Partners from '../../Components/home/Partners/Partners';



const Home = () => {
  return (
    <>
    <Carousel/>
    <ServicesShort/>
    <About/>
    {/* <ProjectsShort/> */}
    {/* <Partners/> */}
    <Contact/>
    </>
  )
}

export default Home;