import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { about, allInOne, barkodOxuyucu, barkodPrinter, barkodProcessor, cardReader, dataTerminal, hero, pulQutulari } from '../../../assets/index';
import { useTranslation } from 'react-i18next';


export const products = [
  {
    image: pulQutulari,
    name: "Bio-L birtərəfli", 
    path: "/products/1",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  },
  {
    image: allInOne,
    name: "Bio-L birtərəfli",
    path: "/products/2",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  },
  {
    image: barkodProcessor,
    name: "Bio-L birtərəfli",
    path: "/products/3",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  },

  {
    image: cardReader,
    name: "Bio-L birtərəfli",
    path: "/products/4",
    content: "MƏRKƏZİ SIRA ÜZƏRİNDƏ VƏ EYNİ ANDA BİTKİ ARASI SİRZƏYİ ALAQ OTLArdan təmizləmək..."
  }

];

const About = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
   <div>
     <div className="md:pt-[80px] md:pb-[80px] max-w-containerSm md:max-w-container mx-auto mt-10 md:mt-0 ">
       <div className='md:h-[456px] md:flex'>
        <div className='hidden md:block'> 
          <img className='md:w-[345px] mr-[44px]' src={about}></img>
        </div>
        <div className='md:w-[671px]'>
          <div className='md:mt-[88px] md:mb-[88px]'>
            <p className='text-[#405d72] text-[20px] font-semibold mb-4'>Haqqımızda</p>
            <p className='text-black text-[22px] md:text-3xl font-bold mb-3'>İnşaat sektorunda qabaqcıl şirkət olduğumuza əminik!</p>
            <p className='mb-3'>“FUGA GROUP” MMC 2016-cı ildə təsis edilib.  Şirkət öz fəaliyyətini lisenziya əsasında həyata keçirir. lisenziya 22 aprel 2021-cı il tarixində verilmişdir. № el-153/2021 “FUGA GROUP” MMC əsaslı tikinti və təchizat  üzrə bütün növ xidmətlər göstərən bir şirkətdir. Qısa zaman ərzində şirkətimiz nüfuzlu tikinti şirkətlərindən birinə çevrildi - əsas etibarilə bu işlərimizdə yeniliklərin tədbiqi, kollektivimizin zəhməti və tikinti sahəsində rəqabətə davamlılığımızla bağlı oldu. İşimizi təhlükəsiz, yüksək keyfiyyətlə və vaxtında icra etməyimiz – müştərilərimizin bizə olan inamını daha da artırır.</p>
            <a href='/about'><button className='w-[146px] h-10 bg-[#f1b559] hover:bg-[#898989] rounded-lg text-white text-base font-normal'>Daha ətraflı</button></a>
          </div>
        </div>
       </div>
      </div>
   </div>
  );
};

export default About;
