import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';
import { logo, logoOther, mpost } from "../../assets/index";
import { useTranslation, initReactI18next } from "react-i18next";
import { azflag, engflag, rusflag } from '../../assets/index';
import i18n from "i18next";
import enTranslation from "../i18n/en/translation.json";
import azTranslation from "../i18n/az/translation.json";
import rusTranslation from "../i18n/rus/translation.json";

const languageOptions = [
  { value: "en", label: "EN", flag: engflag },
  { value: "az", label: "AZ", flag: azflag },
  { value: "rus", label: "RU", flag: rusflag },
];
 
i18n.use(initReactI18next).init({
  resources: {
      en: { translation: enTranslation }, 
      az: { translation: azTranslation },
      rus: { translation: rusTranslation },
  },
  lng: localStorage.getItem("language") || "az",
  fallbackLng: "az",
  interpolation: { escapeValue: false },
});


const MainHeader = () => {
  const { t, i18n } = useTranslation();
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const productsTimeoutRef = useRef(null);
  const servicesTimeoutRef = useRef(null);
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [showSubmenu2, setShowSubmenu2] = useState(false);

  // Scroll değişimini takip eden useEffect
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  
  const [activeMenu, setActiveMenu] = useState(null);

  const toggleMenu = (menuIndex) => {
    setActiveMenu(activeMenu === menuIndex ? null : menuIndex);
  };

  const toggleHamburgerMenu = () => setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
 



  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions.find(lang => lang.value === i18n.language));

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      const language = languageOptions.find(lang => lang.value === savedLanguage);
      if (language) {
        setSelectedLanguage(language);
        i18n.changeLanguage(language.value);
      }
    }
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    setIsOpen(false);
    i18n.changeLanguage(language.value);
    localStorage.setItem("language", language.value);
  }; 

  return (
    <> 
      <nav
        className={`top-0 left-0 w-full z-10 h-[91px] fixed transition-colors duration-300 ${
          scrollY > 0 ? "bg-[#F1F1F1] shadow-md" : "bg-transparent"
        } border-b-[1px] border-[white]`}>
        <div className="md:max-w-container max-w-containerSm mx-auto">
          <div className="mx-auto flex items-center justify-between h-[91px]">
            <div>
              <a href='/'> <img src={`${scrollY > 0 ? logo: logo}`} alt="Logo" className="h-[43px]" /></a>
            </div>
            <div className="hidden lg:flex gap-5 md:w-[807px] justify-between ">
              <a href="/" className={`${scrollY > 0 ? "text-[#f1b559]" : "text-[#f1b559]"} text-base font-normal`}>
                {t("ANA SƏHİFƏ")}
              </a>
              <div
      className="relative inline-block"
      onMouseEnter={() => setShowSubmenu(true)}
      onMouseLeave={() => setShowSubmenu(false)}
    >
      <a
        href="/services"
        className={`${scrollY > 0 ? "text-black" : "text-white"} text-base font-normal`}
      >
        {t("XİDMƏTLƏR")}
      </a>

      {showSubmenu && (
        <div className="absolute bg-white shadow-lg w-[202px] rounded">
          <a
            href="/services/tikinti"
            className="block px-4 py-2 text-black hover:bg-[#f1f1f1] text-base font-normal"
          >
            Tikinti
          </a>
          <a
            href="/services/temir"
            className="block px-4 py-2 text-black hover:bg-[#f1f1f1] text-base font-normal"
          >
            Təmir
          </a>
          <a
            href="/services/metal"
            className="block px-4 py-2 text-black hover:bg-[#f1f1f1] text-base font-normal"
          >
            Metal konstruksiyasının istehsalı
          </a>
        </div>
      )}
    </div>
              <a href="/about" className={`${scrollY > 0 ? "text-black" : "text-white"} text-base font-normal`}>
                {t("HAQQIMIZDA")}
              </a>
              {/* <div
      className="relative inline-block"
      onMouseEnter={() => setShowSubmenu2(true)}
      onMouseLeave={() => setShowSubmenu2(false)}
    >
      <a
        href="/projects"
        className={`${scrollY > 0 ? "text-black" : "text-white"} text-base font-normal`}
      >
        {t("LAYİHƏLƏR")}
      </a>

      {showSubmenu2 && (
        <div className="absolute bg-white shadow-lg w-[202px] rounded">
          <a
            href="/projects/finished"
            className="block px-4 py-2 text-black hover:bg-[#f1f1f1] text-base font-normal"
          >
            Bitmiş layihələr
          </a>
          <a
            href="/projects/unfinished"
            className="block px-4 py-2 text-black hover:bg-[#f1f1f1] text-base font-normal"
          >
            Davam edən layihələr
          </a>
        </div>
      )}
    </div> */}
              <a href="/vakansiya" className={`${scrollY > 0 ? "text-black" : "text-white"} text-base font-normal`}>
                {t("VAKANSİYALAR")}
              </a>
              <a href="/partners" className={`${scrollY > 0 ? "text-black" : "text-white"} text-base font-normal`}>
                {t("PARTNYORLAR")}
              </a>
              <a href="/media" className={`${scrollY > 0 ? "text-black" : "text-white"} text-base font-normal`}>
                {t("MEDİA")}
              </a>
              <a href="/contact" className={`${scrollY > 0 ? "text-black" : "text-white"} text-base font-normal`}>
                {t("ƏLAQƏ")}
              </a>
            </div>
            <div>
              <a href="/contact">
                <button className="bg-[#f1b559] hover:bg-[#898989] hidden md:inline w-[146px] rounded-lg h-10 text-[white]">
                  Layihəm var
                </button>
              </a>
              <div className="relative inline-flex items-center space-x-4 mt-4 sm:mt-0 sm:ml-0 flex-wrap sm:flex-nowrap">
            <button
              onClick={toggleDropdown}
              className={`flex items-center space-x-2 px-4 py-2  rounded-full ${scrollY > 0 ? "text-black" : "text-white"}`}
            >
              <span>{selectedLanguage.label}</span>
              <i className={`fas fa-chevron-down ${scrollY > 0 ? "text-black" : "text-white"}`}></i>
            </button>

            {isOpen && (
              <div className="absolute left-2 top-10 mt-2 text-[white] w-28 rounded-md shadow-lg bg-[#F1B559] ring-1 ring-black ring-opacity-5 z-50">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  {languageOptions.map((language) => (
                    <button
                      key={language.value}
                      onClick={() => handleLanguageChange(language)}
                      className="flex items-center space-x-2 px-4 py-2 w-full text-left hover:bg-[#898989]"
                    >
                      <span>{language.label}</span>
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
          <button className="text-[white] focus:outline-none md:hidden" onClick={toggleHamburgerMenu}>
        <i className="fa-solid fa-bars text-[white] text-[24px]"></i>
      </button>

      {isHamburgerMenuOpen && (
        <div className={`fixed inset-y-0 left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out bg-white w-full z-50 shadow-lg`}>

          <div className="flex flex-col items-start w-full">
          
           
          
            <div className=" items-center w-[100%] justify-center sm:justify-end h-screen">
              <div className="items-center h-[80px] bg-[#F1F1F1] flex justify-between p-4">
              <div>
              <a href='/'> <img src={logo} alt="Logo" className="h-[43px]" /></a>
            </div> 
          <button className=" text-white p-2 rounded" onClick={toggleHamburgerMenu}>
          <i className="fa-solid fa-xmark text-[black] text-[24px]"></i>
          </button>
              </div>
            <div className="items-center gap-4 h-[300px] p-4"> 

                    {/* Button 3 */}
      <div className="w-full relative ">
        <a href="/">
        <button
          onClick={() => toggleMenu('menu1')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Ana səhifə")}
        </button>
        </a>
      </div>

      {/* Button 1 */} 
      <div className="w-full relative z-50"> {/* Menüye dış z-index ekledik */}
      <button
  onClick={() => toggleMenu('menu2')}
  className="w-full h-[50px] text-[black] rounded-lg flex items-center justify-between"
>
  <span>Xidmətlərimiz</span>
  <i className="fas fa-chevron-down text-black"></i>
</button>

  {activeMenu === 'menu2' && (
    <div className="absolute left-0 right-0 mt-2 space-y-2 bg-gray-100 rounded shadow-lg z-50 "> {/* Menüye iç z-index ekledik */}
     <a 
  href="/services/temir" 
  className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out"
>
  {t("Təmir")}
</a>

      <a href="/services/tikinti" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
        {t("Tikinti")}
      </a>
      <a href="/services/metal" className="block px-4 py-2 bg-gray-200 rounded hover:bg-[#264D92] hover:text-white transition duration-300 ease-in-out">
        {t("Metal konstruksiyaların istehsalı")}
      </a>
    </div>
  )}
</div>

                    {/* Button 3 */}
                    <div className="w-full relative ">
        <a href="/vakansiya">
        <button
          onClick={() => toggleMenu('menu3')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Vakansiyalar")}
        </button>
        </a>
      </div>
      
                    {/* Button 3 */}
                    <div className="w-full relative">
        <a href="/partners">
        <button
          onClick={() => toggleMenu('menu4')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Partnyorlar")}
        </button>
        </a>
      </div>
      
                    {/* Button 3 */}
                    <div className="w-full relative">
        <a href="/media">
        <button
          onClick={() => toggleMenu('menu5')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Media")}
        </button>
        </a>
      </div>
      
                    {/* Button 3 */}
                    <div className="w-full relative mb-5">
        <a href="/contact">
        <button
          onClick={() => toggleMenu('menu6')}
          className="w-full h-[50px] text-[black] rounded-lg text-left"
        >
          {t("Əlaqə")}
        </button>
        </a>
      </div>


    </div>
            <div className="p-4" >
              <a href="/contact" className="flex text-[#f1b559]">
                <span className="font-semibold ">{t("Layihəm var")}</span>
              </a>
              {/* <button
              onClick={toggleDropdown}
              className="flex items-center space-x-1 px-3 py-1 border rounded-full border-[#264D92] text-sm sm:text-base mt-2 sm:mt-0"
              style={{ minWidth: "70px" }}
            >
              <img
                src={selectedLanguage.flag}
                alt={`${selectedLanguage.label} Flag`}
                className="h-4 w-4 sm:h-5 sm:w-5 mr-1"
              />
              <span className="text-black">{selectedLanguage.label}</span>
            </button>
            {isOpen && (
              <div className="absolute  w-28 rounded-md shadow-lg bg-[#264D92] ring-1 ring-black ring-opacity-5 z-50">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  {languageOptions.map((language) => (
                    <button
                      key={language.value}
                      onClick={() => handleLanguageChange(language)}
                      className="flex items-center space-x-2 px-4 py-2 w-full text-left hover:bg-[#5e8ee0]"
                    >
                      <img src={language.flag} alt={`${language.label} Flag`} className="h-4 w-4 mr-1" />
                      <span>{language.label}</span>
                    </button>
                  ))}
                </div>
              </div>
            )} */}
            </div>

          </div>
          </div>
        </div>
      )}   
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default MainHeader;
