import React from "react";
import Heading from "../../Components/home/Heading";
import AboutUs from '../../Components/home/About/About';
import Team from "../../Components/home/Team/Team";
import { useTranslation } from "react-i18next";
import { arrow, hero, svg1, svg2, svg3, svg4, svg5, svg7, svgWhite1, svgWhite2, svgWhite3, svgWhite4, svgWhite5, svgWhite7, whiteArrow } from "../../assets";
import Header from "../../Components/Header/Header";

export default function Services() {

  const { t, i18n } = useTranslation();
  return (
    <>
    <Header/>
    <div className="mx-auto bg-[#FAFAFA] pt-[140px] pb-[144px]">
       <div className="md:max-w-container max-w-containerSm mx-auto md:h-[468px]">
    <p className="text-[#405d72] text-[20px] font-semibold mb-4">Xidmətlərimiz</p>
    <p className="text-black text-3xl font-bold mb-3">Professional inşaat xidmətlərimiz</p>
    <p className="text-black text-base font-semibold mb-6">Peşəkar yanaşma, innovativ texnologiyalar və mükəmməlliyə doğru hər addımda xidmətinizdəyik.</p>
    <p className="text-black text-base font-normal mb-10">Bizim əsas məqsədimiz, tikinti sahəsində mükəmməlliyi təmin edərək, müasir texnologiyaların və innovativ həllərin tətbiqi ilə hər bir layihənin keyfiyyətini artırmaqdır. Peşəkar komandamız, inşaat prosesinin hər mərhələsində – layihələndirmədən başlayaraq, tikintinin idarə olunmasına və son tamamlama işlərinə qədər – yüksək standartlara uyğun xidmət göstərir.</p>
    <div className='grid grid-cols-1 md:grid-cols-3 md:h-[225px] gap-5'>

    <div className='rounded-lg border border-[#405d72]/20 md:w-[426px] hover:bg-[#405d72] hover:text-[white] group transition duration-300 ease-in-out'>
  <div className='mt-4 mb-4 mr-6 ml-6 h-[193px]'>
    <p className='mb-[53px] text-[#f1b559] text-lg font-normal group-hover:text-white'>01</p>

    {/* group-hover kullanarak resmin hover durumunda değişmesini sağlıyoruz */}
    <img className="w-10 h-10 mb-2 group-hover:hidden" src={svg1} alt="icon" />
    <img className="w-10 h-10 mb-2 hidden group-hover:block" src={svgWhite1} alt="icon hover" />

    <p className='mb-[20px] text-black text-xl font-bold group-hover:text-white'>Tikinti</p>
    <div className="flex">
      <a href="/services/tikinti">
        <p className='text-black text-sm font-normal group-hover:text-white mr-1'>Daha ətraflı</p>
      </a>
      <img className="w-5 h-5 group-hover:hidden" src={arrow} alt="icon" />
      <img className="w-5 h-5 mb-2 hidden group-hover:block" src={whiteArrow} alt="icon hover" />
    </div>
  </div>
</div>
<div className='rounded-lg border border-[#405d72]/20 md:w-[426px] hover:bg-[#405d72] hover:text-[white] group transition duration-300 ease-in-out'>
  <div className='mt-4 mb-4 mr-6 ml-6 h-[193px]'>
    <p className='mb-[53px] text-[#f1b559] text-lg font-normal group-hover:text-white'>02</p>
    <img className="w-10 h-10 mb-2 group-hover:hidden" src={svg2} alt="icon" />
    <img className="w-10 h-10 mb-2 hidden group-hover:block" src={svgWhite2} alt="icon hover" />
    <p className='mb-[20px] text-black text-xl font-bold group-hover:text-white'>Təmir</p>
    <div className="flex">
      <a href="/services/temir">
        <p className='text-black text-sm font-normal group-hover:text-white mr-1'>Daha ətraflı</p>
      </a>
      <img className="w-5 h-5 group-hover:hidden" src={arrow} alt="icon" />
      <img className="w-5 h-5 mb-2 hidden group-hover:block" src={whiteArrow} alt="icon hover" />
    </div>
  </div>
</div> 
<div className='rounded-lg border border-[#405d72]/20 md:w-[426px] hover:bg-[#405d72] hover:text-[white] group transition duration-300 ease-in-out'>
  <div className='mt-4 mb-4 mr-6 ml-6 h-[193px]'>
    <p className='mb-[53px] text-[#f1b559] text-lg font-normal group-hover:text-white'>03</p>
    <img className="w-10 h-10 mb-2 group-hover:hidden" src={svg7} alt="icon" />
    <img className="w-10 h-10 mb-2 hidden group-hover:block" src={svgWhite7} alt="icon hover" />
    <p className='mb-[20px] text-black text-xl font-bold group-hover:text-white'>Metal konstruksiyaların istehsalı</p>
    <div className="flex">
      <a href="/services/metal">
        <p className='text-black text-sm font-normal group-hover:text-white mr-1'>Daha ətraflı</p>
      </a>
      <img className="w-5 h-5 group-hover:hidden" src={arrow} alt="icon" />
      <img className="w-5 h-5 mb-2 hidden group-hover:block" src={whiteArrow} alt="icon hover" />
    </div>
  </div>
</div>
        </div>
     </div>
     
</div>
    </>
  );
}
