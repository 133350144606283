import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../../Components/Header/Header";
import { hero, service4 } from "../../../assets";

export default function Interyer() {

  const { t, i18n } = useTranslation();
  return (
    <>
    <Header/>
    <div className="mx-auto bg-[#FAFAFA] pt-[140px] pb-[144px]">
       <div className="max-w-container mx-auto h-[713px]">
    <p className="text-[#405d72] text-[20px] font-semibold mb-4">Xidmətlərimiz</p>
    <p className="text-black text-3xl font-bold mb-10">İnteryer</p>
   <img className="h-[394px] bg-black/20 rounded-lg w-full" src={service4}></img>
   <p className="text-black text-base font-normal mt-6">"Fuga MMC" interyer dizaynın yaradılması ilə bağlı xidmət göstərir. İstənilən çətinlikdə olan layihənin icrasını öz üzərimizə götürürük və məqbul bir zaman müddətində həyata keçiririk! Şirkətimiz tərəfindən sənaye, özəl, müxtəlif ictimai obyektlər tövsiyyələrimiz əsasında inşa edilmiş və yaxud yenidən qurulmuşdur. Öz işimizə cavabdehik və onun yüksək keyfiyyətinə zəmanət veririk. Eksteryer dizaynı "Fuga MMC"-yə həvalə edərək, işin uğurla nəticələnəcəyinə arxayın ola bilərsiniz!</p>
     </div>
     
</div>
    </>
  );
}
