import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../../Components/Header/Header";
import { hero, metal, service6 } from "../../../assets";

export default function Metal() {

  const { t, i18n } = useTranslation();
  return (
    <>
    <Header/>
    <div className="mx-auto bg-[#FAFAFA] pt-[140px] pb-[144px]">
       <div className="max-w-container mx-auto h-[713px]">
    <p className="text-[#405d72] text-[20px] font-semibold mb-4">Xidmətlərimiz</p>
    <p className="text-black text-3xl font-bold mb-10">Metal konstruksiyaların istehsalı</p>
   <img className="h-[394px] bg-black/20 rounded-lg w-full" src={metal}></img>
   <p className="text-black text-base font-normal mt-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vehicula leo ut ligula pretium vestibulum. Maecenas vel tortor ac libero varius scelerisque eget eu ligula. Pellentesque sed justo eros. Maecenas porttitor sem eros, non porta mi facilisis sit amet. Integer vel eros dignissim, hendrerit dolor at, hendrerit elit. Nulla mollis sed mi eu facilisis. Vivamus ornare nisi sagittis venenatis efficitur. Ut dui lorem, finibus sit amet feugiat suscipit, consectetur a ligula.</p>
     </div>
     
</div>
    </>
  );
}
