import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { about, allInOne, barkodOxuyucu, barkodPrinter, barkodProcessor, cardReader, dataTerminal, hero, pulQutulari, sertifikat1, sertifikat2, sertifikat3, sertifikat4, sertifikat5, sertifikat6, sertifikat7 } from '../../assets/index';
import { useTranslation } from 'react-i18next';
import Header from '../../Components/Header/Header';



const About = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
 <>
 <Header/>
   <div className='bg-[#FAFAFA] pb-[144px]'>
      <div>
     <div className="pt-[140px] pb-[80px] md:max-w-container max-w-containerSm mx-auto">
       <div className='flex'>
        <div>
          <div>
          <p className='text-[#1b2335] text-[20px] font-semibold mb-3'>Haqqımızda</p>
            <p className='text-black text-sm font-normal mb-4'>“FUGA GROUP” MMC 2016-cı ildə təsis edilib.  Şirkət öz fəaliyyətini lisenziya əsasında həyata keçirir. lisenziya 22 aprel 2021-cı il tarixində verilmişdir. № el-153/2021 “FUGA GROUP” MMC əsaslı tikinti və təchizat  üzrə bütün növ xidmətlər göstərən bir şirkətdir. Qısa zaman ərzində şirkətimiz nüfuzlu tikinti şirkətlərindən birinə çevrildi - əsas etibarilə bu işlərimizdə yeniliklərin tədbiqi, kollektivimizin zəhməti və tikinti sahəsində rəqabətə davamlılığımızla bağlı oldu. İşimizi təhlükəsiz, yüksək keyfiyyətlə və vaxtında icra etməyimiz – müştərilərimizin bizə olan inamını daha da artırır. Şirkətin portfoliosuna dövlət obyektləri ilə bərabər bir sıra özəl obyektlər də daxildir. Şirkətimiz böyük təcrübəyə malik mühəndis və usta heyətinə malikdir. tikinti sektorunun dinamik inkişafını nəzərə alaraq şirkətimiz göstərilən xidmətlərdə üstünlüyünü saxlamaq üçün müasir texnologiyalardan istifadəyə xüsusi önəm verməkdədir. Belə yanaşma həm bizim müştərilərimizə, həm bizə maksimal fayda verir. Şirkətimizin personal bazarın tələblərinin dəyişməsinə və yeni müştərilərin istəklərinə operativ reaksiya vermək üçün hərtərəfli təlimatlandırılmışdırlar. Ənənəvi olaraq məqsədimiz ilk növbədə keyfiyyət amilinə nəzarət etməkdir.</p>
            <p className='text-[#1b2335] text-[20px] font-semibold mb-3'>Bizim missiyamız</p>
            <p className='text-black text-sm font-normal mb-4'>“FUGA GROUP” yarandığı gündən hesab edir ki, iqtisadiyyatın inkişafı üçün atılan addımlar iqtisadiyyatın inkişafı üçün yetərli deyil. biz inanırıq ki, iqtisadi layihələr dünyanın mədəniyyət kolleksiyası və müxtəlif sosial davranışlarla əlaqələndirildikdə və onlardan ilhamlandıqda səmərəli şəkildə həyata keçirilə bilər. “FUGA GROUP” bu dəyərli irsi yaratmaq, qorumaq və gənclərə ötürmək missiyasını dünən olduğu kimi eyni həvəslə yerinə yetirir. “FUGA GROUP”  Azərbaycanın mədəni dəyərlərinə təkcə ölkəmiz üçün deyil, bütün dünya üçün böyük sərvət kimi baxır və bu dəyərlərin qorunub saxlanmasını və gələcək nəsillərə çatdırılmasını öz missiyasının bir hissəsi hesab edir.</p>
            <p className='text-[#1b2335] text-[20px] font-semibold mb-3'>Əsas prinsiplərimiz</p>
            <p className='text-black text-sm font-normal mb-4'>Bizim texniki təhlükəsizliyə verdiyimiz önəm hər zaman qürur duymağımıza əsas olmuşdur.  Şirkət keyfiyyəti fəaliyyətinin əsas prinsipi hesab edir ki, bu da müəyyən sahələrdə liderlik iddiasında mühüm rol oynayır.</p>
            <p className='text-[#1b2335] text-[20px] font-semibold mb-3'>Məqsədlərimiz</p>
            <p className='text-black text-sm font-normal mb-4'>Şirkət əsasən əsaslı  tikinti və təchizat ilə məşğuldur. hazırda biz tikinti və istehsalat sahələrində uğurla fəaliyyət göstəririk. Ölkəmizdə sürətlə inkişaf edən tikinti sektorunda zəngin təcrübəmizlə qarşıya qoyduğumuz məqsədlərə geniş və dinamik heyətlə nail oluruq. Başladığımız hər işi vaxtında başa vurduq və həmişə verdiyimiz sözlərə əməl etdik. Biz çalışdığımız insanlara həmişə etibar etmişik və onların etimadını qazanmışıq. bu etimaddan aldığımız güc və ilhamla tikinti-istehsalat sahəsində gördüyümüz işlərlə xalq təsərrüfatına öz töhfəmizi verməklə bütün işləri vaxtında, qüsursuz və standartlara uyğun uğurla başa vurduq. Biz görülən işlərin keyfiyyətini nəzərə alaraq, əməkdaşlarımızın və ətraf mühitin təhlükəsizliyini hər zaman hər şeydən üstün tutmuşuq. qarşıdakı illərdə məqsədimiz layihələrimizə müasir texnologiyada innovasiyaları tətbiq etməklə, sürətlə inkişaf edən tikinti sektorunda mövqeyimizi daha da möhkəmləndirməkdir. Yüksək təcrübəli kadrlarımız və texniki imkanlarımızla daha da böyük layihələr həyata keçirəcəyimizə inanırıq.</p>
          </div>
        </div>
       </div>
      </div>
      </div>
      <div className='bg-[#F4F4F4] mt-10'>
      <div className="pt-[80px] pb-[80px] max-w-containerSm md:max-w-container mx-auto">
       <div>
     <p className='text-center text-black text-[22px] md:text-[30px] md:text-3xl font-bold mx-auto'>Sertifikatlarımız</p>
     <div className='mt-[40px] grid grid-cols-1 md:grid-cols-4 gap-5 w-full'>
<div className='w-[295px] mx-auto md:mx-0'><img src={sertifikat7}></img>  </div>
<div className='w-[295px] mx-auto md:mx-0'><img src={about}></img>  </div>
     </div>

       </div>
      </div>
      </div>
   </div></>
  );
};

export default About;
